<template>
  <div>
    <div class="fullscreenLoading" v-show="fullscreenLoading">
      <div
        :style="{
          transform:
            fenshu == 'h'
              ? 'scaleX(' + screenRadio + ')'
              : 'scaleY(' + screenRadios + ')',
        }"
        class="wrap_full"
      >
        <img :src="require('@/assets/card/loading.gif')" class="img_card" />
      </div>
    </div>

     <!-- 置顶 -->
     <div style="width: 100%;     height: 100vh;
                          position: fixed;
                          top:0;
                          z-index: 222;"  v-if="acceptData.activity.add_info.dan_layout == 2">
                      <!--弹幕区域容器-->
                      <div style="width: 100%; height: 400px;" class="danmuContainer">
                        <!--弹幕区域-->
                        <div class="danmu danmu-plugin"></div>
                      </div>
                    </div>
                    <!-- 置底 -->
                    <div style="width: 100%;height: 100vh;
                          position: fixed;
                          bottom:0;
                          z-index: 222;"  v-if="acceptData.activity.add_info.dan_layout == 1 ">
                      <!--弹幕区域容器-->
                      <div
                        style="
                          width: 100%;
                          height: 300px;
                          position: absolute; bottom: 0; " class="danmuContainer">
                        <!--弹幕区域-->
                        <div class="danmu danmu-plugin"></div>
                      </div>
                    </div>


    <div
      class="bg_wrap"
      v-if="
        (state != 'going' && state == 'unstart') ||
        (state != 'going' && state == 'finish')
      "
    >
      <div
        v-if="state != 'going' && state == 'unstart'"
        class="activity-start"
        :class="{ horizontal: mode == 'h', vertical: mode == 'v' }"
        :style="{
          transform:
            fenshu == 'h'
              ? 'scaleX(' + screenRadio + ')'
              : 'scaleY(' + screenRadios + ')',
        }"
      >
        <img :src="require('@/assets/card/start_bg.png')" class="img_card" />
      </div>

      <div
        v-if="state != 'going' && state == 'finish'"
        class="activity-finish"
        :class="{ horizontal: mode == 'h', vertical: mode == 'v' }"
        :style="{
          transform:
            fenshu == 'h'
              ? 'scaleX(' + screenRadio + ')'
              : 'scaleY(' + screenRadios + ')',
        }"
      >
        <img :src="require('@/assets/card/end_bg.png')" class="img_card" />
      </div>
    </div>
    <div
      class="activity"
      v-if="acceptData?.activity && state == 'going'"
      v-show="!fullscreenLoading"
    >
      <block v-if="screen_part_type !== 0">
        <img
          :src="acceptData?.adv_bottom_img"
          style="
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          "
          v-if="this.acceptData.screen_type != 0"
        />
        <div
          v-else
          style="
            height: 100vh;
            position: relative;
            background-repeat: no-repeat !important;
            background-position: top !important;
          "
          :style="{
            'background-color':
              mode == 'v' ? acceptData?.activity?.bottom_color : '',
            'background-image':
              mode == 'h' ? 'url(' + acceptData?.activity.bg_img + ')' : '',
          }"
        ></div>
      </block>
      <block v-else>
        <div
        v-if="bgImgs.length==1"
          style="
            position: relative;
            background-repeat: no-repeat !important;
            background-position: top !important;
            background-attachment: fixed;
  background-size: cover;
  height: 100%;
          "
          :style="{
            'background-color': acceptData?.activity?.bottom_color,
            'background-image':
              mode == 'h'
                ? 'url(' + acceptData?.activity.bg_img + ')'
                : 'url(' + acceptData?.activity.bg_img + ')',
          }"
        ></div>


        <el-carousel
                        indicator-position="none"
                        :interval="5000"
                        class="custom-carousel"
                        v-if="bgImgs.length > 1"
                        height="100vh"
                      >
                        <el-carousel-item
                          v-for="(item, index) in bgImgs"
                          :key="index"
                        >
                          <img
                            v-if="item"
                            class="activity-bg-img"
                            :src="item"
                          />
                         
                        </el-carousel-item>
                      </el-carousel>

                      <video v-if="bgVideos" controls="controls" autoplay muted name="media"  style="width: 100%;height: 100vh; position: fixed;top: 0;left: 0;right: 0;bottom: 0;"
                                              loop>
                             <source :src="bgVideos" style="width:100%;height:100%">
                     </video>


      </block>

      <div
        class="activity-wrap"
        :style="{ width: wrapWidth, height: wrapHeight }"
        :class="{
          mCenter: mode == 'h' && screen_part_type == 2,
          dingwei: mode == 'v',
          resopnse: this.acceptData.screen_type == 0,
        }"
        ref="activityWrap"
      >
        <div
          :class="{ liangfen: screen_part_type == 3 && fenshu == 'v' }"
          :style="{
            top:
              acceptData.screen_type != 0 &&
              screen_part_type == 3 &&
              fenshu == 'v'
                ? mt
                : '',
          }"
        >
          <div
            class="activityResponse"
            v-show="state == 'going'"
            ref="container"
            style="background-size: 100% 100%"
            :class="{
              'activity-container': this.acceptData.screen_type == 0,
              horizontal: mode == 'h',
              vertical: mode == 'v',
              no_adv: !acceptData.activity.left_banner_img,
            }"
            :style="{
              'background-color':
                this.acceptData.screen_type != 0
                  ? acceptData?.activity?.bottom_color
                  : '',
              'background-image':
                this.acceptData.screen_type != 0
                  ? 'url(' + acceptData?.activity.bg_img + ')'
                  : '',
            }"
          >
            <div
              class="activity-bg"
             
            >
              <!-- <div
                class="activity-counter"
                ref="counter"
                v-if="
                  JSON.stringify(countObj) != '[]' &&
                  ((acceptData.activity &&
                    (acceptData.activity.online_show == 1 ||
                      acceptData.activity.join_show == 1)) ||
                    acceptData.reside_show == 1 ||
                    acceptData.flow_show == 1)
                "
              >
                <ul>
                  <li
                    :style="{
                      transform:
                        mode == 'h'
                          ? 'scaleX(' + screenRadio + ')'
                          : 'scaleY(' + screenRadio + ')',
                      'margin-right':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                      'margin-left':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                    }"
                    v-if="
                      acceptData.current_select.current == 'activity' &&
                      acceptData.activity.online_show == 1
                    "
                  >
                    <strong class="count-font green">{{
                      countObj.online || 0
                    }}</strong>
                    <span>实时在线人数</span>
                  </li>
                  <li
                    :style="{
                      transform:
                        mode == 'h'
                          ? 'scaleX(' + screenRadio + ')'
                          : 'scaleY(' + screenRadio + ')',
                      'margin-right':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                      'margin-left':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                    }"
                    v-if="
                      acceptData.current_select.current == 'activity' &&
                      acceptData.activity.join_show == 1
                    "
                  >
                    <strong class="count-font orange">{{
                      countObj.join || 0
                    }}</strong>
                    <span>累计参与人数</span>
                  </li>
                  <li
                    v-if="acceptData.reside_show == 1"
                    :style="{
                      transform:
                        mode == 'h'
                          ? 'scaleX(' + screenRadio + ')'
                          : 'scaleY(' + screenRadio + ')',
                      'margin-right':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                      'margin-left':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                    }"
                  >
                    <strong class="count-font yellow">{{
                      countObj.flow
                    }}</strong>
                    <span>驻留人数</span>
                  </li>
                  <li
                    v-if="acceptData.flow_show == 1"
                    :style="{
                      transform:
                        mode == 'h'
                          ? 'scaleX(' + screenRadio + ')'
                          : 'scaleY(' + screenRadio + ')',
                      'margin-right':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                      'margin-left':
                        -((85 * (1 - screenRadio)) / 2 + 10) + 'px',
                    }"
                  >
                    <strong class="count-font purple">{{
                      countObj.pass
                    }}</strong>
                    <span>客流人次</span>
                  </li>
                </ul>
              </div> -->
              <div class="activity-main">
                <div
                  class="activity-content-side"
                  v-if="mode == 'h'"
                  :style="{
                    transform:
                      mode == 'h'
                        ? 'scaleX(' + screenRadio + ')'
                        : 'scaleY(' + screenRadio + ')',
                    'margin-left': mleft + 'px',
                  }"
                >
                  <div
                    class="activity-module-qrcode"
                    v-if="acceptData && acceptData.activity.qrcode_img && is_show_daping_qrcode"
                    :style="{
                      'background-image':
                        'url(' + acceptData.activity.qrcode_img + ')',
                    }"
                  >
                    <div class="activity-module-qrcode-item">
                      <img :src="acceptData.activity.qrcode_path" />
                    </div>
                  </div>

                  <div
                    class="activity-module-prize"
                    v-if="!!acceptData && acceptData.activity.add_info.is_show_daping_prize=='1' && acceptData.activity.add_info.is_prize=='1'"
                    :style="{
                      'background-image':
                        'url(' + acceptData.activity.prize_img + ')',
                    }"
                  >
                    <div class="activity-module-prize-carousel">
                      <div class="unnum__wrap">
                        <img
                          class="unnum"
                          src="https://img.chinamallsales.com/unnum.png"
                        />
                        <div class="unnum__wrap_content">
                          <div>剩余</div>
                          <div>{{ victory_unused_num }}份</div>
                        </div>
                      </div>
                      <el-carousel
                        indicator-position="none"
                        :interval="5000"
                        @change="handleCarouselChange"
                        v-if="prizeList.length > 0"
                      >
                        <el-carousel-item
                          v-for="(item, index) in prizeList"
                          :key="index"
                        >
                          <img
                            v-if="item.victory_img"
                            class="activity-module-prize-carousel-img"
                            :src="item.victory_img"
                          />
                          <img
                            v-else
                            class="activity-module-prize-carousel-img"
                            src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"
                          />
                          <p class="activity-module-prize-carousel-title">
                            {{ item.victory_name }}
                          </p>
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>

                  <div
                    class="activity-module-adv"
                    v-if="acceptData.activity.left_banner_img"
                  >
                    <img
                      class="activity-module-adv-img activity-module-adv-img-h"
                      :src="acceptData.activity.left_banner_img"
                    />
                  </div>
                </div>
                <div
                  class="activity-content-middle"
                  ref="middWidth"
                  :style="{
                    'margin-right': mleft + 'px',
                    'margin-left': mleft + 'px',
                  }"
                >
                  <div class="activity-title" style="position: fixed">
                    <img
                      :src="acceptData.activity.title_img"
                      :style="{
                        transform:
                          mode == 'h'
                            ? 'scaleX(' + screenRadio + ')'
                            : 'scaleY(' + screenRadio + ')',
                      }"
                    />
                  </div>

                  <div class="activity-title">
                    <img
                      :src="acceptData.activity.title_img"
                      :style="{
                        transform:
                          mode == 'h'
                            ? 'scaleX(' + screenRadio + ')'
                            : 'scaleY(' + screenRadio + ')',
                      }"
                    />
                  </div>
                  <div class="activity-content-item" ref="content" v-if="is_show_dan">
                   
                    <!-- 置顶部 -->
                    <!-- <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 2 
                      "
                      style="position: fixed; top: 0"
                    >

                      <div
                        class="marquee"
                        :style="{ 'animation-duration': duration + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                          :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head"  :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}">
                              <img
                                v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+27 + 'px',
                                height: imgHeight+27 + 'px',
                              }"
                              />
                              <img
                              :style="{
                                width: imgWidth + 'px',
                                height: imgHeight + 'px',
                              }"
                                class="head-img"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>

                            
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                          </div>

                          <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                        </div>
                      </div> 
                   

                     
                    </div> -->
                    <!-- 置底部 -->
                    <!-- <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 1 
                      "
                      style="position: fixed; bottom: 0;z-index:333;"
                    >
                      <div
                        class="marquee"
                        :style="{ 'animation-duration': duration + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                          :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head" :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}">
                              <img
                                v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+27 + 'px',
                                height: imgHeight+27 + 'px',
                              }"
                              />
                              <img
                                class="head-img"
                                :style="{
                                width: imgWidth + 'px',
                                height: imgHeight + 'px',
                              }"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                          </div>
                          <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                        </div>
                      </div>
                    </div>
               -->

                    <!-- 置左边h -->
                    <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 3 &&
                        mode == 'h'
                      "
                      style="
                        position: fixed;
                        left: 270px;
                        bottom: 0;
                        height: 100vh;
                      "
                    >
                    
                      <!-- <div
                        class="marqueeLeft"
                        style="height: 100vh"
                        :style="{ 'animation-duration': durationLeft + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                         style="display: flex"
                          :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head" :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}">
                              <img
                                v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+27 + 'px',
                                height: imgHeight+27 + 'px',
                              }"
                              />
                              <img
                                class="head-img"
                                :style="{
                                width: imgWidth + 'px',
                                height: imgHeight + 'px',
                              }"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                          </div>
                         
                        </div>
                      </div> -->
                    </div>

                      <!-- 置左边v -->
                      <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 3 &&
                        mode == 'v'
                      "
                      style="
                        position: absolute;
                        left:0;
                        bottom: 0;
                        height: 100%;
                      "
                    >
                      <div
                        class="marqueeLeft"
                        style="height: 100%"
                        :style="{ 'animation-duration': durationLeft + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                          style="display: flex"
                            :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head" :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}">
                              <img
                               v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+7 + 'px',
                                height: imgHeight+7 + 'px',
                              }"
                              />
                              <img
                              :style="{
                                width: imgWidth + 'px',
                                height: imgHeight + 'px',
                              }"
                                class="head-img"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                          </div>
                         
                        </div>
                      </div>
                    </div>

                    <!-- 置右边h -->
                    <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 4 &&
                        mode == 'h'
                      "
                      style="
                        position: fixed;
                        right: 265px;
                        bottom: 0;
                        height: 100vh;
                      "
                    >
                      <div
                        class="marqueeLeft"
                        style="height: 100vh"
                        :style="{ 'animation-duration': durationLeft + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                             style="display: flex;justify-content: flex-end;"
                             :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head"  :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}">
                              <img
                              v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+27 + 'px',
                                height: imgHeight+27 + 'px',
                              }"
                              />
                              <img

                              :style="{
                                width: imgWidth + 'px',
                                height: imgHeight + 'px',
                              }"
                                class="head-img"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                          </div>
                          <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                        </div>
                      </div>
                    </div>


                     <!-- 置右边v -->
                     <div
                      class="index-index-container"
                      v-if="
                        acceptData.activity.add_info.dan_layout == 4 &&
                        mode == 'v'
                      "
                      style="
                        position: absolute;
                        right: 0px;
                        bottom: 0;
                        height: 100%;
                      "
                    >
                      <div
                        class="marqueeLeft"
                        style="height: 100%"
                        :style="{ 'animation-duration': durationLeft + 's' }"
                      >
                        <div
                          class="marquee-text"
                          v-for="(item, index) in swiperList"
                          :key="index"
                          style="display: flex;justify-content: flex-end;"
                             :style="{
                                marginRight: imgWidth- 35 + 'px',
                                marginBottom: imgHeight- 35 + 'px',
                              }"
                        >
                          <div class="info-box-item">
                            <div class="item-head"  :class="{ 'pd6': item.image && acceptData.activity.add_info.is_avatar=='1'}"  
                           
                            >
                              <img
                                v-if="item.image && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcon"
                                :src="item.image"
                                :style="{
                                width: imgWidth+7 + 'px',
                                height: imgHeight+7 + 'px',
                              }"
                              />
                              <img
                                class="head-img"
                                :src="
                                  item.avatar
                                    ? item.avatar
                                    : 'https://img.chinamallsales.com/user/avatarCommon.png?1'
                                "
                              />
                            </div>
                            <div
                             v-if="isColor"
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                                color:colors[index%5]
                              }"
                            >
                              {{ item.content }}
                            </div>
                            <div
                             v-else
                              class="leave-msg"
                              :style="{
                                fontSize: fontSize + 'px',
                                opacity: transparency,
                              }"
                            >
                              {{ item.content }}
                            </div>
                          </div>
                          <img
                                v-if="item.imageIcon && acceptData.activity.add_info.is_avatar=='1'"
                                class="imageIcons"
                                :src="item.imageIcon"
                              />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="activity-content-prize">
                                <ul class="prize-list">
                                    <div class="prize-list_wrap">
                                        <vue-seamless-scroll :class-option="optionSwitch1" class="seamless-warp3" 
                                            :data="prizeCountList" v-if="prizeCountList.length > 4 && acceptData.screen_type != 8" >
                                            <div v-for="(item, index) in prizeCountList" :key="index" class="prize_wrap"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }"
                                          >
                                                <div class="li"   :class="{'ml25':prizeCountList.length>=5}">
                                                    <img class="prize-list-img" :src="item.victory_img" v-if="item.victory_img"/>
                                                    <p class="doubble">{{ item.victory_level_name }}</p>
                                                    <span class="prize-list-count">剩<i>{{ item.victory_unused_num || 0
                                                    }}</i>份</span>
                                                    <p class="prize_name">{{ item.victory_name }}</p>
                                                </div>
                                            </div>
                                        </vue-seamless-scroll>

                                        <vue-seamless-scroll :class-option="optionSwitch360" class="seamless-warp3"
                                            :data="prizeCountList" v-if="prizeCountList.length > 4&& acceptData.screen_type == 8" >
                                            <div v-for="(item, index) in prizeCountList" :key="index" class="prize_wrap"
                                            :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')') }"
                                          >
                                                <div class="li"   :class="{'':prizeCountList.length>=5}">
                                                    <img class="prize-list-img" :src="item.victory_img" buv-if="item.victory_img"/>
                                                    <p class="doubble">{{ item.victory_level_name }}</p>
                                                    <span class="prize-list-count">剩<i>{{ item.victory_unused_num || 0
                                                    }}</i>份</span>
                                                    <p class="prize_name">{{ item.victory_name }}</p>
                                                </div>
                                            </div>
                                        </vue-seamless-scroll>
                                        <div v-for="(item, index) in prizeCountList" :key="index"
                                        style="padding:0 0.5%;" v-if="prizeCountList.length<5" 
                                            class="prize_wrap prize_wraps" :style="{ 'transform': mode == 'h' ? ('scaleX(' + screenRadio + ')') : ('scaleY(' + screenRadio + ')'),'height':113*screenRadio +'px' }">
                                            <div class="li" style="width:100%">
                                                <img class="prize-list-img" :src="item.victory_img" v-if="item.victory_img"/>
                                                <div  class="prize-list-img" v-else></div>
                                                <p class="doubble">{{ item.victory_level_name }}</p>
                                                <span class="prize-list-count" >剩<i>{{ item.victory_unused_num || 0
                                                }}</i>份</span>
                                                <p class="prize_name" v-if="item.victory_name">{{ item.victory_name }}</p>
                                            </div>
                                        </div>

                                    </div>

                                </ul>
                            </div>   -->
                </div>
                <div
                  class="activity-content-side"
                  v-if="mode == 'h' && acceptData.activity.add_info.is_prize=='1' && acceptData.activity.add_info.is_show_daping_prize_user=='1'"
                  :style="{
                    transform:
                      mode == 'h'
                        ? 'scaleX(' + screenRadio + ')'
                        : 'scaleY(' + screenRadio + ')',
                    'margin-right': mleft + 'px',
                  }"
                >
                  <div class="activity-module-rank hen">
                    <ul>
                      <li
                        v-for="(item, index) in topUserList"
                        :class="{ win: item.win_type > 0 }"
                        :key="index"
                      >
                        <img
                          class="gongxi"
                          src="https://img.chinamallsales.com/gongxis.png"
                          v-if="item.nickname != '- -'"
                        />
                        <img class="rank-head" :src="item.avatar" />
                        <div class="rank-info">
                          <p class="rank-info-name">{{ item.nickname }}</p>
                          <div class="rank-info-process">
                            <p>
                              <span
                                style="
                                  margin-right: 4px;
                                  font-size: 12px;
                                  color: #a67027;
                                "
                                >名次</span
                              >
                              <span
                                v-if="item.victory_level == 1"
                                style="margin-left: 4px"
                              >
                                一等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 2"
                                style="margin-left: 4px"
                              >
                                二等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 3"
                                style="margin-left: 4px"
                              >
                                三等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 4"
                                style="margin-left: 4px"
                              >
                                四等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 5"
                                style="margin-left: 4px"
                                >五等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 6"
                                style="margin-left: 4px"
                                >六等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 7"
                                style="margin-left: 4px"
                                >七等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 8"
                                style="margin-left: 4px"
                                >八等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 9"
                                style="margin-left: 4px"
                                >九等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 10"
                                style="margin-left: 4px"
                                >十等奖
                              </span>
                              <span v-else style="margin-left: 4px">-- </span>
                            </p>
                            <p v-if="item.victory_name">
                              获得
                              <span style="margin-left: 4px">{{
                                item.victory_name
                              }}</span>
                            </p>
                            <p v-else>
                              获得 <span style="margin-left: 4px">--</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="activity-module-adv"
                    v-if="acceptData.activity.right_banner_img"
                  >
                    <img
                      class="activity-module-adv-img activity-module-adv-img-h"
                      :src="acceptData.activity.right_banner_img"
                    />
                  </div>
                </div>
                <div class="activity-content-bottom" v-if="mode == 'v'">
                  <div
                    class="activity-module-qrcode"
                    v-if="acceptData && acceptData.activity.qrcode_img"
                    :style="{
                      'background-image':
                        'url(' + acceptData.activity.qrcode_img + ')',
                    }"
                  >
                    <div class="activity-module-qrcode-item">
                      <img :src="acceptData.activity.qrcode_path" />
                    </div>
                  </div>
                  <div class="activity-module-rank vers">
                    <ul>
                      <li v-for="(item, index) in topUserListV" :key="index">
                        <img
                          class="gongxis"
                          src="https://img.chinamallsales.com/gongxis.png"
                          v-if="item.nickname != '- -'"
                        />
                        <img class="rank-head" :src="item.avatar" />
                        <div class="rank-info">
                          <p class="rank-info-name">{{ item.nickname }}</p>
                          <div class="rank-info-process rank-info-process-v">
                            <p>
                              <span
                                style="
                                  margin-right: 4px;
                                  font-size: 12px;
                                  color: #a67027;
                                "
                                >名次</span
                              >
                              <span
                                v-if="item.victory_level == 1"
                                style="margin-left: 4px"
                              >
                                一等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 2"
                                style="margin-left: 4px"
                              >
                                二等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 3"
                                style="margin-left: 4px"
                              >
                                三等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 4"
                                style="margin-left: 4px"
                              >
                                四等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 5"
                                style="margin-left: 4px"
                                >五等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 6"
                                style="margin-left: 4px"
                                >六等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 7"
                                style="margin-left: 4px"
                                >七等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 8"
                                style="margin-left: 4px"
                                >八等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 9"
                                style="margin-left: 4px"
                                >九等奖
                              </span>
                              <span
                                v-else-if="item.victory_level == 10"
                                style="margin-left: 4px"
                                >十等奖
                              </span>
                              <span v-else style="margin-left: 4px">-- </span>
                            </p>
                            <p v-if="item.victory_name">
                              获得
                              <span style="margin-left: 4px">{{
                                item.victory_name
                              }}</span>
                            </p>
                            <p v-else>
                              获得<span style="margin-left: 4px">{{
                                item.victory_name
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="activity-module-wanjia"  v-if="acceptData && acceptData.activity.prize_img"
                    :style="{
                      'background-image':
                        'url(' + acceptData.activity.prize_img + ')',
                    }">
                      <div class="activity-module-prize-carousel">
                        <div class="unnum__wrap_mobile">
                        <img
                          class="unnum"
                          src="https://img.chinamallsales.com/unnum.png"
                        />
                        <div class="unnum__wrap_content">
                          <div>剩余</div>
                          <div>{{ victory_unused_num }}份</div>
                        </div>
                      </div>
                        <el-carousel indicator-position="none" height="230px" :interval="5000"
                          v-if="prizeList.length > 0"    @change="handleCarouselChange">
                          <el-carousel-item v-for="(item, index) in prizeList" :key="index">
                            <img v-if="item.victory_img" class="activity-module-prize-carousel-img"
                              :src="item.victory_img"  style="width:183px;height:183px;"/>
                            <img v-else class="activity-module-prize-carousel-img"
                              src="https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b"  style="width:183px;height:183px;" />
                            <p class="activity-module-prize-carousel-title">{{ item.victory_name }}</p>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                </div>
                <div
                  class="activity-adv"
                  v-if="
                    mode == 'v' &&
                    acceptData.activity.left_banner_img &&
                    acceptData.activity.right_banner_img
                  "
                >
                  <div
                    class="activity-module-adv"
                    v-if="acceptData.activity.left_banner_img"
                  >
                    <img
                      class="activity-module-adv-img activity-module-adv-imgs"
                      :src="acceptData.activity.left_banner_img"
                    />
                  </div>
                  <div
                    class="activity-module-adv"
                    v-if="acceptData.activity.right_banner_img"
                  >
                    <img
                      class="activity-module-adv-img activity-module-adv-imgs"
                      :src="acceptData.activity.right_banner_img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 中奖明细节 -->

            <!-- <div class="mask" ref="mask" :style="{ opacity: 0 }">
              <div
                class="pop-winner"
                :style="{
                  transform:
                    mode == 'h'
                      ? 'scaleX(' + screenRadio + ')'
                      : 'scaleY(' + screenRadio + ')',
                }"
                v-if="
                  newList.length <= 3 || (newList.length == 4 && mode == 'h')
                "
              >
                <div class="pop-winner_wrap">
                  <div
                    v-for="(item, index) of newList"
                    :key="index"
                    :class="['pop-' + item.index, 'show']"
                  >
                    <transition
                      enter-active-class="animated bounceIn"
                      leave-active-class="animated bounceOut"
                      :duration="600"
                    >
                      <div class="winner-item">
                        <div class="winner-body">
                          <div class="winner-name">{{ item.nickname }}</div>
                          <div class="winner-prz">
                            {{ item.victory_name }}
                          </div>
                          <div class="winner-prz-info">
                            <img
                              class="winner-prz-info-img"
                              :src="item.victory_img"
                              v-if="item.victory_img"
                            />
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div
                class="pop-winner pop-winners"
                v-if="newList.length == 4 && mode == 'v'"
                :style="{
                  transform:
                    mode == 'h'
                      ? 'scaleX(' + screenRadio + ')'
                      : 'scaleY(' + screenRadio + ')',
                }"
              >
                <div class="pop-winner_wrap pop-winner_wraps">
                  <div
                    v-for="(item, index) of newList"
                    :key="index"
                    class="winner-items"
                    :class="['pop-' + item.index, 'show']"
                  >
                    <transition
                      enter-active-class="animated bounceIn"
                      leave-active-class="animated bounceOut"
                      :duration="600"
                    >
                      <div class="winner-item">
                        <div class="winner-body">
                          <div class="winner-name">{{ item.nickname }}</div>
                          <div class="winner-prz">
                            {{ item.victory_name }}
                          </div>
                          <div class="winner-prz-info">
                            <img
                              class="winner-prz-info-img"
                              :src="item.victory_img"
                              v-if="item.victory_img"
                            />
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div> -->
        <!-- 二维码弹窗 -->
        
            <block v-if="is_show_daping_qrcode">
          <div
              class="mask_yindao heng"
              v-if="mode == 'h'"
              ref="mask_yindao"
              :style="{ opacity: 0 }"
            >
              <div :class="['pop-' + 'show']">
                <div class="mask_yindao_wrap">
                  <div
                    class="mask_yindao_content"
                    :class="{
                      mt240:
                        (screen_part_type == 2 &&
                          fenshu == 'h' &&
                          prizeCountListT.length >= 3) ||
                        (screen_part_type == 3 &&
                          fenshu == 'h' &&
                          prizeCountListT.length >= 3),
                    }"
                  >
                    <img
                      class="sao"
                      src="../../../assets/card/sao.png"
                      :style="{
                        transform:
                          mode == 'h'
                            ? 'scaleX(' + screenRadio + ')'
                            : 'scaleY(' + screenRadio + ')',
                      }"
                    />
                    <div
                      class="sao_wrap sao_wrap_img"
                      :style="{
                        transform:
                          mode == 'h'
                            ? 'scaleX(' + screenRadio + ')'
                            : 'scaleY(' + screenRadio + ')',
                      }"
                    >
                      <img
                        class="code"
                        :src="acceptData.activity.qrcode_path"
                      />
                    </div>
                    <img
                      class="layer_prize"
                      src="../../../assets/card/layer_prizes.png"
                      :style="{
                        transform:
                          mode == 'h'
                            ? 'scaleX(' + screenRadio + ')'
                            : 'scaleY(' + screenRadio + ')',
                      }"
                    />
                  </div>
                  <div
                    class="layerLst_wrap"
                    :class="{
                      weizhi:
                        (screen_part_type == 2 &&
                          fenshu == 'h' &&
                          prizeCountListT.length >= 3) ||
                        (screen_part_type == 3 &&
                          fenshu == 'h' &&
                          prizeCountListT.length >= 3),
                    }"
                    :style="{
                      transform:
                        prizeCountListT.length >= 3
                          ? 'translateX(' + (100 - 100 / screenRadio) / 2 + '%)'
                          : '',
                      left:
                        prizeCountListT.length >= 3
                          ? (100 - 100 / screenRadio) / 2 + '%'
                          : '',
                      width:
                        prizeCountListT.length >= 3
                          ? 100 / screenRadio + '%'
                          : '',
                      transform:
                        mode == 'h'
                          ? 'scaleX(' + screenRadio + ')'
                          : 'scaleY(' + screenRadio + ')',
                    }"
                  >
                    <div v-for="(item, index) in prizeCountListT" :key="index">
                      <div class="victory_bg">
                        <img
                          class="victory_img"
                          :src="item.victory_img"
                          v-if="item.victory_img"
                        />
                        <span class="victory_img_div" v-else> </span>
                        <div class="victory_name" v-if="item.victory_img">
                          <p class="victory_text">{{ item.victory_name }}</p>
                        </div>
                        <div class="victory_names" v-else>
                          <p class="victory_text">{{ item.victory_name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mask_yindao ver"
              v-if="mode == 'v' && this.prizeCountListT.length == 5"
              ref="mask_yindao"
              :style="{ opacity: 0 }"
            >
              <div class="mask_yindao_wrap mask_yindao_wrap05">
                <div class="mask_yindao_content">
                  <img class="sao" src="../../../assets/card/sao.png" />
                  <div class="sao_wrap">
                    <img class="code" :src="acceptData.activity.qrcode_path" />
                  </div>
                  <img
                    class="layer_prize"
                    src="../../../assets/card/layer_prizes.png"
                  />
                </div>
                <div class="layerLst_wrap layerLst_wrap05">
                  <div v-for="(item, index) in prizeCountListT" :key="index">
                    <div class="victory_bg">
                      <img
                        class="victory_img"
                        :src="item.victory_img"
                        v-if="item.victory_img"
                      />
                      <span class="victory_img_div" v-else> </span>
                      <div class="victory_name" v-if="item.victory_img">
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                      <div class="victory_names" v-else>
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mask_yindao ver"
              v-else-if="mode == 'v' && this.prizeCountListT.length == 4"
              ref="mask_yindao"
              :style="{ opacity: 0 }"
            >
              <div class="mask_yindao_wrap mask_yindao_wrap04">
                <div class="mask_yindao_content">
                  <img class="sao" src="../../../assets/card/sao.png" />
                  <div class="sao_wrap">
                    <img class="code" :src="acceptData.activity.qrcode_path" />
                  </div>
                  <img
                    class="layer_prize"
                    src="../../../assets/card/layer_prizes.png"
                  />
                </div>
                <div class="layerLst_wrap">
                  <div v-for="(item, index) in prizeCountListT" :key="index">
                    <div class="victory_bg">
                      <img
                        class="victory_img"
                        :src="item.victory_img"
                        v-if="item.victory_img"
                      />
                      <span class="victory_img_div" v-else> </span>
                      <div class="victory_name" v-if="item.victory_img">
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                      <div class="victory_names" v-else>
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mask_yindao ver"
              v-else-if="mode == 'v' && this.prizeCountListT.length < 4"
              ref="mask_yindao"
              :style="{ opacity: 0 }"
            >
              <div class="mask_yindao_wrap mask_yindao_wrap03">
                <div class="mask_yindao_content">
                  <img class="sao" src="../../../assets/card/sao.png" />
                  <div class="sao_wrap">
                    <img class="code" :src="acceptData.activity.qrcode_path" />
                  </div>
                  <img
                    class="layer_prize"
                    src="../../../assets/card/layer_prizes.png"
                  />
                </div>
                <div class="layerLst_wrap">
                  <div v-for="(item, index) in prizeCountListT" :key="index">
                    <div class="victory_bg">
                      <img
                        class="victory_img"
                        :src="item.victory_img"
                        v-if="item.victory_img"
                      />
                      <span class="victory_img_div" v-else> </span>
                      <div class="victory_name" v-if="item.victory_img">
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                      <div class="victory_names" v-else>
                        <p class="victory_text">{{ item.victory_name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
        </block>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let danmuPlugin;
let pageCurrent = "index";
let selectedActivity = "";
let newListData = [];
let lastListData = [];
import vueSeamlessScroll from "vue-seamless-scroll";
import { TweenMax } from "gsap";
import {

  getLatestListlele,
  getPrizeNewLstLiu,
  getTopPrizeUserListliu,
  getPrizeNumListCard,
  getTopPointsUserListlele,
  getOverView,
  lstmsgLiu,
  getPrizeList,
} from "@/api/littleGameOne.js";
import { throwStatement } from "@babel/types";

export default {
  name: "moduleCard",
  components: {
    vueSeamlessScroll,

  },

  data() {
    return {
  optionSwitch360:{
                    direction: 2,
                    autoPlay: true,
                    limitMoveNum: 4,
                    waitTime: 2000,

                },
      currentIndex: 0,
      duration: 15,
      durationLeft: 15,
      swiperList: [],
      isscaleSuo: true,
      leftVal: 0, // 轮播图盒子的偏移值
      flag: true, // 用来节流防止重复点击j
      start: null, // 自动执行下一张定的时器
      imgWidth: 500, // 在这里填写你需要的图片宽度
      ition: 0.8, // 设置轮播图过度时间
      imgShow: 0,
      uniq: "",
      acceptData: {},
      mode: "h",
      state: "going",
      countObj: {
        online: 0,
        join: 0,
        flow: 0,
        pass: 0,
      },
      prizeList: [],
      userList: [],
      topUserList: [],
      topUserListV: [],
      userData: [],
      playerLst: [],
      prizeCountList: [],
      prizeCountListT: [],
      newList: [],
      count: 0,
      countRefresh: 0,
      currentIndex: 0,
      randomArr: [],
      scale: 1,
      oldUserList: [],
      layerLst: [],
      isspectail: false,
      scaleFen: 1,
      wrapWidth: "",
      wrapHeight: "",
      bgx: "",
      reversed_width: "",
      reversed_height: "",
      scaleFen: 1,
      mleft: 0,
      mRight: 0,
      screen_part_type: 2,
      userLeft: 0,
      screenRadios: 1,
      fenshu: "h",
      mt: 0,
      transparency: 1,
      fontSize: "40",
      imgWidth:'51',
      imgHeight:'51',
      victory_unused_num: "",
      bgImgs:[],
      bgVideos:'',
      colors:[
        '#FFD600',
        '#4ECB05',
        '#1BAOFF',
        '#921CDF',
        '#FFFFFF',
      ],
      isColor:false,
      is_show_daping_qrcode:false,
      pageIndex:1,
      is_show_dan:true,
      speed:120,
      dan_line:3,
    };
  },

  mounted() {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/danmu.js";
    document.body.appendChild(script);
    script.onload = () => {
      danmuPlugin = new Danmu("danmu-plugin", {
        trackNum: 3, //弹幕轨道最大行数
        trackHeight: 90, //每行弹幕轨道的高度
      });
    };
    this.screen_type = localStorage.getItem("screen_type");
    let w = 0;
    let h = 0;
    if (this.screen_type == 9) {
      w = 1920;
      h = 560;
    } else if (this.screen_type == 8) {
      w = 1920;
      h = 360;
    } else if (this.screen_type == 7) {
      w = 1920;
      h = 640;
    } else if (this.screen_type == 6) {
      w = 1920;
      h = 1080;
    } else if (this.screen_type == 5) {
      w = 1600;
      h = 1200;
    } else if (this.screen_type == 4) {
      w = 1400;
      h = 1400;
    } else if (this.screen_type == 3) {
      w = 1000;
      h = 1500;
    } else if (this.screen_type == 2) {
      w = 1000;
      h = 2000;
    } else if (this.screen_type == 1) {
      //自定义
      w = localStorage.getItem("screen_width");
      h = localStorage.getItem("screen_length");
    } else if (this.screen_type == 0) {
      //默认自适应
      w = 1920;
      h = 1080;
      this.screen_part_type = 0;
    }

    //横屏
    if (16 / 9 < w / h) {
      this.fenshu = "h";
      this.wrapHeight = "100vh";
      this.wrapWidth = (16 / 9) * h + "px";
      if (this.screen_type == 0) {
        //默认自适应
        this.screenRadio = 1;
        this.mleft = 0;
      } else {
        //3分|| 2分屏
        this.screenRadio = 16 / 9 / (w / h);
        this.mleft = -(250 * (1 - this.screenRadio) * 0.5);
      }
    } else {
      //竖屏
      this.fenshu = "v";
      this.wrapWidth = "100%";
      this.screenRadio = 1;
      this.screenRadios = (9 / 16) * (w / h);
      console.log(this.screenRadios);
    }

    this.state = localStorage.getItem("state");
    let that = this;
    setInterval(() => {
      that.open();
      setTimeout(() => {
        that.close();
      }, 5000);
    }, 11000);

    this.coll_id = this.$route.params.id;
    this.fullscreenLoading = true;
    //  this.resizeSetting();
    console.log(localStorage.getItem("screen_type"));

    setInterval(this.scrollMessage, 2000);
  },

  methods: {
    handleCarouselChange(val) {
      console.log(val);
      this.currentIndex = val;
      this.victory_unused_num = this.prizeList[val].victory_unused_num;
    },
    open() {
      TweenMax.to(this.$refs.mask_yindao, 1, {
        opacity: 1,
        zIndex: "9999",
      });
      // this.$refs.movieBox.style.zIndex = "888";
    },
    close() {
      TweenMax.to(this.$refs.mask_yindao, 1, {
        opacity: 0,
        zIndex: "777",
      });
      // setTimeout(() => {
      //   this.$refs.movieBox.style.zIndex = "9999";
      // }, 500);
    },
    getParentData(data) {
      if (
        (pageCurrent && pageCurrent != data.current_select.current) ||
        (selectedActivity &&
          selectedActivity != data.current_select.selectValue)
      ) {
        if (this.countRefresh == 1) {
          this.fullscreenLoading = true;
          window.location.reload();
          return;
        }
        this.countRefresh++;
      }
      selectedActivity = data.current_select.selectValue;
      pageCurrent = data.current_select.current;

      this.uniq = data.activity.uniq;
      this.acceptData = { ...data };
      this.coll_id = this.$route.params.id;
      this.screen_part_type = this.acceptData.screen_part_type;
      //弹幕字号 
      if (this.acceptData.activity.add_info.dan_size == 1) {
        this.fontSize = 22;
        this.imgWidth = 40;
        this.imgHeight = 40;
      } else if (this.acceptData.activity.add_info.dan_size == 2) {
        this.fontSize = 30;
        this.imgWidth = 50;
        this.imgHeight = 50;
      } else if (this.acceptData.activity.add_info.dan_size == 3) {
        this.fontSize = 40;
        this.imgWidth = 55;
        this.imgHeight = 55;
      } else if (this.acceptData.activity.add_info.dan_size == 4) {
        this.fontSize = 45;
        this.imgWidth = 68;
        this.imgHeight = 68;
      } else if (this.acceptData.activity.add_info.dan_size == 5) {
        this.fontSize = 50;
        this.imgWidth = 73;
        this.imgHeight = 73;
      }
     //弹幕速度 
      if(this.acceptData.activity.add_info.dan_speed == 1){
        this.duration = "25";
        this.durationLeft = "25";
        this.speed = 60
       
      }else if (this.acceptData.activity.add_info.dan_speed == 2) {
        this.duration = "20";
        this.durationLeft = "20";
        this.speed = 80
      } else if (this.acceptData.activity.add_info.dan_speed == 3) {
        this.duration = "15";
        this.durationLeft = "15";
        this.speed = 120
      } else if (this.acceptData.activity.add_info.dan_speed == 4) {
        this.duration = "10";
        this.durationLeft = "10";
        this.speed = 160
      }else if (this.acceptData.activity.add_info.dan_speed == 5) {
        this.duration = "5";
        this.durationLeft = "5";
        this.speed = 200
      }

      //彩色弹幕
      if(this.acceptData.activity.add_info.is_color=='1'){
        this.isColor = true
      }else{
        this.isColor = false
      }

      //二维码显示

      if(this.acceptData.activity.add_info.is_show_daping_qrcode =='1'){
        this.is_show_daping_qrcode = true
      }else{
        this.is_show_daping_qrcode = false
      }
  //弹幕开关显示

  if(this.acceptData.activity.add_info.is_show_dan =='1'){
        this.is_show_dan = true
      }else{
        this.is_show_dan = false
      }
  //弹幕行数
  this.dan_line = this.acceptData.activity.add_info.dan_line
  console.log(this.dan_line)

      this.transparency = this.acceptData.activity.add_info.transparency / 100;
      console.log(this.acceptData.activity.bg_img.split(","))
      this.bgImgs = this.acceptData.activity.bg_img.split(",")
      this.bgVideos = this.acceptData.activity.bg_video

      let w = 0;
      let h = 0;
      if (this.acceptData.screen_type == 9) {
        w = 1920;
        h = 560;
      } else if (this.acceptData.screen_type == 8) {
        w = 1920;
        h = 360;
      } else if (this.acceptData.screen_type == 7) {
        w = 1920;
        h = 640;
      } else if (this.acceptData.screen_type == 6) {
        w = 1920;
        h = 1080;
      } else if (this.acceptData.screen_type == 5) {
        w = 1600;
        h = 1200;
      } else if (this.acceptData.screen_type == 4) {
        w = 1400;
        h = 1400;
      } else if (this.acceptData.screen_type == 3) {
        w = 1000;
        h = 1500;
      } else if (this.acceptData.screen_type == 2) {
        w = 1000;
        h = 2000;
      } else if (this.acceptData.screen_type == 1) {
        //自定义
        w = this.acceptData.screen_width;
        h = this.acceptData.screen_length;
      } else if (this.acceptData.screen_type == 0) {
        //默认自适应
        w = 1920;
        h = 1080;
        this.screen_part_type = 0;
      }

      //横屏
      if (16 / 9 < w / h) {
        this.fenshu = "h";
        this.wrapHeight = "100vh";
        this.wrapWidth = (16 / 9) * h + "px";
        if (this.acceptData.screen_type == 0) {
          //默认自适应
          this.screenRadio = 1;
          this.mleft = 0;
        } else {
          //3分|| 2分屏
          this.screenRadio = 16 / 9 / (w / h);
          this.mleft = -(250 * (1 - this.screenRadio) * 0.5);
        }
      } else {
        //竖屏
        this.fenshu = "v";
        this.wrapWidth = "100%";
        this.screenRadio = 1;
        this.screenRadios = (9 / 16) * (w / h);
        if (this.$refs.activityWrap) {
          this.$refs.activityWrap.style.transform =
            "scaleY(" + this.screenRadios + ")";
        }

        if (this.acceptData.screen_part_type == 3) {
          //2分屏幕
          const _h = document.body.clientHeight;
          this.mt = (_h - _h * this.screenRadios) / this.screenRadios + "px";
        } else if (this.acceptData.screen_part_type == 2) {
          //3分屏幕
          if (this.$refs.activityWrap) {
          }
        }
      }

      this.getPrizeList();
      this.getPrizeListData();
     // this.getUserList();
      this.getTopUserLists();
      this.getPrizeListCount();
      this.getPrizeListCountT();
      this.getTopUserList();
    //  this.getTopPointsUserListwanjia();
      this.getOverViewNum(this.acceptData.activity.uniq);
      this.lstmsgLiuData();

      //表示活动未开始
      const current = new Date().getTime();
      if (data.activity) {
        if (current < this.acceptData.activity.begin_time * 1000) {
          //  this.state = 'unstart';
          this.count = 0;
        } else if (
          current > this.acceptData.activity.end_time * 1000 ||
          this.acceptData.activity?.is_termi == 2
        ) {
          console.log(111);
          // this.state = 'finish';
          this.count = 0;
        } else {
          selectedActivity = data.current_select.selectValue;
          this.current = data.current_select.current;
          this.uniq = data.current_select.selectValue;
          this.defaultcurrent = data.current_select.current;
          this.activeDetail = data;
          //  this.state = 'going';
          this.isscaleSuo = data.activity.resolution;
          if (this.count == 0) {
            this.$nextTick(() => {
              this.resizeSetting();
              window.addEventListener("resize", () => {
                this.resizeSetting();
              });
            });
          }
          this.count++;
        }
      } else {
        selectedActivity = data.current_select.selectValue;
        this.current = data.current_select.current;
        this.uniq = data.current_select.selectValue;
        this.defaultcurrent = data.current_select.current;
        this.activeDetail = data;
        //this.state = 'going';
      }
      let that = this;
      setInterval(() => {
        that.fullscreenLoading = false;
      }, 500);
    },
    arrayCompare(newArr, lastArr) {
      if (lastArr == undefined || newArr == undefined) {
        return [];
      }
      if (lastArr.length == 0 || newArr.length == 0) {
        return newArr;
      }
      var newArr2 = JSON.parse(JSON.stringify(newArr)); //复制出新数组
      for (var i in lastArr) {
        var id = lastArr[i].id;
        for (var j in newArr2) {
          if (id === newArr2[j].id) {
            newArr2.splice(j, 1);
            break;
          }
        }
      }
      return newArr2;
    },

    //获取奖池列表
    getPrizeListData() {
      getPrizeList({ colle_uniq: this.coll_id, uniq: this.uniq }).then(
        (res) => {
          if (res.status == 200) {
            this.prizeList = res.data;
            this.victory_unused_num =
              this.prizeList[this.currentIndex].victory_unused_num;
            this.prizeList.map((item) => {
              return {
                ...item,
                victory_img: item.victory_img
                  ? item.victory_img
                  : "https://img.chinamallsales.com/d4fa8f5fab5c2d74beaf59146ba9b74b",
              };
            });
          }
        }
      );
    },

    //获取最新中奖
    getPrizeList() {
      getPrizeNewLstLiu({ colle_uniq: this.coll_id, uniq: this.uniq }).then(
        (res) => {
          newListData = res.data;
          var addArr = this.arrayCompare(newListData, lastListData);
          lastListData = newListData;
          if (addArr.length > 0) {
            this.newList = addArr;
            TweenMax.to(this.$refs.mask, 1, {
              opacity: 1,
              zIndex: "99999",
            });
            this.getUserPop();
          }
        }
      );
    },

    getUserPop() {
      setTimeout(() => {
        this.newList = [];
        if (this.newList.length == 0) {
          TweenMax.to(this.$refs.mask, 1, {
            opacity: 0,
            zIndex: "333",
          });
        }
      }, 2000);
    },
    getOverViewNum(uniq) {
      getOverView({
        uniq: uniq,
        colle_uniq: this.coll_id,
      }).then((res) => {
        if (res.status == 200) {
          this.countObj = res.data;
        }
      });
    },
    //最新参与列表
    getUserList() {
      getLatestListlele({ colle_uniq: this.coll_id, uniq: this.uniq }).then(
        (res) => {
          let userData = res.data;
          if (userData.length != this.oldUserList.length) {
            const chabie = userData.length - this.oldUserList.length;
            this.currentIndex = chabie - 1;
            if (this.currentIndex != -1) {
              this.userData.push(userData[this.currentIndex]);
              TweenMax.to(this.$refs.box, 1, {
                scrollTop: this.$refs.box.scrollHeight,
              });
              this.currentIndex--;
            }

            this.oldUserList = this.userData;
          }
        }
      );
    },
    //获取前十中奖用户
    getTopUserList() {
      getTopPrizeUserListliu({
        colle_uniq: this.coll_id,
        uniq: this.uniq,
        limit: 10,
      }).then((res) => {
        this.topUserList = res.data;
        const length = 10 - res.data.length;
        if (res.data.length < 10) {
          for (let i = 0; i < length; i++) {
            this.topUserList.push({
              act_id: 0,
              avatar:
                "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
              nickname: "- -",
              victory_name: "- -",
              victory_level_name: "- -",
              win_name: "- -",
              win_type: 0,
            });
          }
        }
      });
    },

   
    //获取留言板数据
    lstmsgLiuData() {
      lstmsgLiu({
        colle_uniq: this.coll_id,
        uniq: this.uniq,
        page:1,
        limit: 1,
        last_sid:this.swiperList.length>0?this.swiperList[this.swiperList.length-1].sid:0,
      }).then((res) => {
        if(this.pageIndex<=res.data.total){
          this.pageIndex++
          let list =  res.data.list
          if(res.data.list.length>0){
            this.swiperList = this.swiperList.concat(list)
          }else{
            this.swiperList =[]
          }
       
          // 带挂件
          danmuPlugin.updateDanmuList([
           {
              speed: this.speed, //滚动速度
              opacity: this.transparency, //弹幕透明度
              danmuHeight: this.imgWidth + 20, //弹幕高度
              danmuAvatarFrameSize:  40, //头像框大小(宽/高)
              danmuAvatarFrameTop: 5,
              danmuAvatarFrameLeft: 5,
              danmuAvatarFrameSrc:"", //头像框图片地址
              danmuAvatarSize: this.imgWidth, //头像大小
              danmuAvatarTop: 10,
              danmuAvatarLeft: 10,
              danmuAvatarSrc:list.length>0?list[0].avatar:'', //头像图片地址
              danmuTextLeft: this.imgWidth + 10 * 2,
              danmuTextSize: this.fontSize, //文字大小
              danmuTextColor:this.isColor?this.colors[this.pageIndex%5]:'#fff', //文字颜色
              danmuTextContent: list.length>0?list[0].content:'', 
            },
          ]);
   
         }
         if(this.pageIndex>res.data.total){
          this.pageIndex =1
         }
         
      });
    },

    //获取前十中奖用户
    getTopUserLists() {
      getTopPrizeUserListliu({
        colle_uniq: this.coll_id,
        uniq: this.uniq,
        limit: 10,
      }).then((res) => {
        this.topUserListV = res.data;
        const lengthV = 10 - res.data.length;
        if (res.data.length < 10) {
          for (let i = 0; i < lengthV; i++) {
            this.topUserListV.push({
              act_id: 0,
              avatar:
                "https://img.chinamallsales.com/415daa77fdf7112f6fbf4c582f270fc4",
              nickname: "- -",
              victory_name: "- -",
              victory_level_name: "- -",
              win_name: "- -",
              win_type: 0,
            });
          }
        }
      });
    },
    //最新玩家
    getTopPointsUserListwanjia() {
      getTopPointsUserListlele({
        colle_uniq: this.coll_id,
        uniq: this.uniq,
      }).then((res) => {
        this.playerLst = res.data;
        const length = 5 - res.data.length;
        if (res.data.length < 5) {
          for (let i = 0; i < length; i++) {
            this.playerLst.push({
              act_id: 0,
              avatar: "",
              nickname: "还未上榜",
              total_points: "- -",
              win_type: 0,
            });
          }
        }
      });
    },
    //剩余奖品数量列表
    getPrizeListCount() {
      getPrizeNumListCard({ colle_uniq: this.coll_id, uniq: this.uniq }).then(
        (res) => {
          for (let i in res.data) {
            if (res.data[i].victory_level == 1) {
              res.data[i].victory_level_name = "一等奖";
            }
            if (res.data[i].victory_level == 2) {
              res.data[i].victory_level_name = "二等奖";
            }
            if (res.data[i].victory_level == 3) {
              res.data[i].victory_level_name = "三等奖";
            }
            if (res.data[i].victory_level == 4) {
              res.data[i].victory_level_name = "四等奖";
            }
            if (res.data[i].victory_level == 5) {
              res.data[i].victory_level_name = "五等奖";
            }
            if (res.data[i].victory_level == 6) {
              res.data[i].victory_level_name = "六等奖";
            }
            if (res.data[i].victory_level == 7) {
              res.data[i].victory_level_name = "七等奖";
            }
            if (res.data[i].victory_level == 8) {
              res.data[i].victory_level_name = "八等奖";
            }
            if (res.data[i].victory_level == 9) {
              res.data[i].victory_level_name = "九等奖";
            }
            if (res.data[i].victory_level == 10) {
              res.data[i].victory_level_name = "十等奖";
            }
          }

          this.prizeCountList = res.data;

        }
      );
    },

    getPrizeListCountT() {
      getPrizeNumListCard({
        colle_uniq: this.coll_id,
        uniq: this.uniq,
        limit: 5,
      }).then((res) => {
        for (let i in res.data) {
          if (res.data[i].victory_level == 1) {
            res.data[i].victory_level_name = "一等奖";
          }
          if (res.data[i].victory_level == 2) {
            res.data[i].victory_level_name = "二等奖";
          }
          if (res.data[i].victory_level == 3) {
            res.data[i].victory_level_name = "三等奖";
          }
          if (res.data[i].victory_level == 4) {
            res.data[i].victory_level_name = "四等奖";
          }
          if (res.data[i].victory_level == 5) {
            res.data[i].victory_level_name = "五等奖";
          }
          if (res.data[i].victory_level == 6) {
            res.data[i].victory_level_name = "六等奖";
          }
          if (res.data[i].victory_level == 7) {
            res.data[i].victory_level_name = "七等奖";
          }
          if (res.data[i].victory_level == 8) {
            res.data[i].victory_level_name = "八等奖";
          }
          if (res.data[i].victory_level == 9) {
            res.data[i].victory_level_name = "九等奖";
          }
          if (res.data[i].victory_level == 10) {
            res.data[i].victory_level_name = "十等奖";
          }
        }
        this.prizeCountListT = res.data;
      });
    },

    getRandNum(minNum, maxNum, decimalNum) {
      let max = 0,
        min = 0;
      minNum <= maxNum
        ? ((min = minNum), (max = maxNum))
        : ((min = maxNum), (max = minNum));
      switch (arguments.length) {
        case 1:
          return Math.floor(Math.random() * (max + 1));
        case 2:
          return Math.floor(Math.random() * (max - min + 1) + min);
        case 3:
          return (Math.random() * (max - min) + min).toFixed(decimalNum);
        default:
          return Math.random();
      }
    },
    getUniqueRand(min, max, num) {
      const randArr = [];
      for (let i = 0; i < num; i++) {
        const rand = this.getRandNum(min, max);
        if (randArr.indexOf(rand) == -1) {
          randArr.push(rand);
        } else {
          i--;
        }
      }
      return randArr;
    },
    resizeSetting() {
      const _w = document.body.clientWidth;
      const _h = document.body.clientHeight;
      this.mode = _w >= _h ? "h" : "v";
      if (this.mode == "h") {
        const rate = _w > 1440 ? 1920 / 1080 : 1440 / 1080;
        this.isOverTop = rate < _w / _h;

        if (this.$refs.container) {
          const fixWidth = _w > 1440 ? 1920 : 1440;
          if (this.isOverTop) {
            this.$refs.container.style.transform = "scale(" + _h / 1080 + ")";
            this.$refs.container.style.marginTop =
              -((1080 - _h) / 2 - 1) + "px";
            this.$refs.container.style.marginLeft = -(fixWidth - _w) / 2 + "px";
          } else {
            const currH = _h / (_w / fixWidth);

            const _style = this.$refs.container.getAttribute("style");
            const filter_style = _style
              ? _style.split(";").filter((item) => {
                  return !(item.indexOf("min-width") > -1);
                })
              : "";
            const _new_style =
              filter_style.length > 0 ? filter_style.join(";") : "";
            this.$refs.container.style = _new_style;
            this.$refs.container.style.transform =
              "scale(" + _w / fixWidth + ")";
            this.$refs.container.style.marginTop = (_h - currH) / 2 + "px";
            this.$refs.container.style.marginLeft =
              -(_w / (_w / fixWidth) - _w) / 2 + "px";
            this.$refs.container.style.minHeight = currH + "px";
            this.$refs.container.style.maxHeight = currH + "px";

            if (this.$refs.content) {
              this.$refs.content.style.height = currH - 484 + "px";
              this.$refs.box.style.minHeight = currH - 500 + "px";

              //新加
              this.$refs.movie.style.width = (currH - 500) / 2.02 + "px";
              this.$refs.movieBox.style.width = (currH - 590) / 2.02 + "px";
              this.$refs.movie.style.height = currH - 534 + "px";
              const currH3 = _h / _w;
              if (currH3 == 1) {
                this.$refs.movieBox.style.height = "calc(100% - 60px )";
              }

              console.log(this.$refs.movie.style.width);
              this.scale = _w / fixWidth;
            }
          }
        }
      } else {
        if (this.$refs.container) {
          const currH = _h / (_w / 1380);
          const currW = _w / (_w / 1380);
          this.$refs.container.style.transform = "scale(" + _w / 1380 + ")";
          this.$refs.container.style.marginTop = -(currH - _h) / 2 + "px";
          this.$refs.container.style.marginLeft = -(currW - _w) / 2 + "px";
          this.$refs.container.style.minHeight = currH + "px";
          this.$refs.container.style.maxHeight = currH + "px";
          if (this.$refs.content) {
            if (this.acceptData.activity.left_banner_img) {
              this.$refs.content.style.height = currH - 1100 + "px";
              this.$refs.box.style.minHeight = currH - 1230 + "px";
              //新加
              this.$refs.movie.style.width = (currH - 1150) / 2.02 + "px";
              this.$refs.movieBox.style.width = (currH - 1230) / 2.02 + "px";
              this.$refs.movie.style.height = currH - 1150 + "px";
              console.log(this.$refs.movie.style.width);
              console.log(this.$refs.box.style.minHeight);
              console.log(2222);
              if (_h / _w >= 2.7) {
                this.isspectail = true;
                let setWidth = _w - 20;
                console.log(setWidth);
                this.$refs.movie.style.width = setWidth / (_w / 1380) + "px";
                this.$refs.movie.style.height =
                  (setWidth * 2.02) / (_w / 1380) + "px";
                console.log((_h - setWidth / (_w / 1380)) / 2);

                console.log(this.$refs.movie.style.height);
              }
            } else {
              this.$refs.content.style.height = currH - 700 + "px";
              //新加
              this.$refs.movie.style.width = (currH - 970) / 2.02 + "px";
              this.$refs.movieBox.style.width = (currH - 1050) / 2.02 + "px";
              this.$refs.movie.style.height = currH - 970 + "px";
              if (_h / _w >= 2.7) {
                this.isspectail = true;
                let setWidth = _w - 20;
                console.log(setWidth);
                this.$refs.movie.style.width = setWidth / (_w / 1380) + "px";
                this.$refs.movie.style.height =
                  (setWidth * 2.02) / (_w / 1380) + "px";
                console.log((_h - setWidth / (_w / 1380)) / 2);

                console.log(this.$refs.movie.style.height);
              }
            }
          }
          this.scale = _w / 1380;
          const currH2 = _h / _w;
          if (currH2 >= 1.3) {
            this.$refs.movieBox.style.height = "calc(100% - 155px )";
          }
        }
      }
    },
  },
};
</script>
<style  scoped lang="less" >
@import url("./style.less");
.weizhi {
  position: absolute;
}
.activity-content-middle {
  width: 100%;
  height: 100%;
  flex: auto;
  overflow: hidden;
  position: relative;
  padding: 0 30px;
}

.seamless-warp3 {
  overflow: hidden;
  width: 1000px;
  margin: 0 auto;
  height: 200px;
}

.activity-user-box {
  border-radius: 60px;
  box-shadow: inset 0px 10px 60px 0px #ffffff;
  background: rgba(255, 255, 255, 0.1);
  height: 100%;
  border: 2px solid #d2f0ff;
  overflow-y: auto;
  list-style-type: none;
  padding: 25px 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  .user-name {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    margin: 10px auto 2px;
    margin-top: 0;
  }

  .user-points {
    color: #fffc00;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    margin: 10px auto 2px;
    margin-top: 0;
  }

  li {
    text-align: center;
    width: 16.6666666666%;
    padding: 0px 0;
    position: relative;
    display: inline-block;

    .success_icon,
    .fail_icon {
      width: 142px;
      height: 64px;
      position: relative;
      top: 35px;
      z-index: 222;
    }

    .level_icon {
      width: 100px;
      height: 28px;
      margin-top: 8px;
    }

    .user-prz {
      padding: 3px 0;
    }

    .user-head {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 60px;
        border: 4px solid #ffd8b2;
        box-sizing: border-box;
        object-fit: cover;
      }
    }

    &.win {
      .user-head {
        &::before {
          content: "";
          display: block;
          width: 116px;
          height: 77px;
          background-image: url(../../../assets/moonBetting/cap.png);
          position: absolute;
          top: -26px;
          left: -31px;
          transform: scale(0.3, 0.9);
        }
      }
    }

    &.win1 {
      .user-prz {
        width: 170px;
        margin: 0 auto;
        background: linear-gradient(
          90deg,
          rgba(255, 219, 43, 0) 0%,
          rgba(255, 225, 7, 0.6) 20%,
          #ffe000 49%,
          rgba(255, 222, 14, 0.6) 79%,
          rgba(255, 223, 35, 0) 100%
        );

        &.no-prz {
          background: none;

          .user-prz-name {
            display: none;
          }
        }
      }

      .user-prz-name {
        background-image: url(../../../assets/moonBetting/prz_name1.png);
        background-size: 210px 672px;
        width: 170px;
        height: 30px;
        margin: 0 auto;
        transform: scale(0.3, 0.9);

        &.prz1 {
          background-position: center -15px;
        }

        &.prz2 {
          background-position: center -70px;
        }

        &.prz3 {
          background-position: center -126px;
        }

        &.prz4 {
          background-position: center -182px;
        }

        &.prz5 {
          width: 210px;
          background-position: center -238px;
          margin-left: -22px;
        }

        &.prz6 {
          width: 180px;
          background-position: center -294px;
        }

        &.prz7 {
          background-position: center -350px;
        }

        &.prz8 {
          background-position: center -406px;
        }

        &.prz9 {
          background-position: center -462px;
        }

        &.prz10 {
          background-position: center -518px;
        }

        &.prz11 {
          background-position: center -574px;
        }

        &.prz12 {
          background-position: center -630px;
        }
      }
    }
  }

  .user-prz-tou {
    i {
      display: inline-block;
      width: 28px;
      height: 30px;
      background-size: 100% 100%;

      &.tou1 {
        background-image: url(../../../assets/moonBetting/tou1.png);
      }

      &.tou2 {
        background-image: url(../../../assets/moonBetting/tou2.png);
      }

      &.tou3 {
        background-image: url(../../../assets/moonBetting/tou3.png);
      }

      &.tou4 {
        background-image: url(../../../assets/moonBetting/tou4.png);
      }

      &.tou5 {
        background-image: url(../../../assets/moonBetting/tou5.png);
      }

      &.tou6 {
        background-image: url(../../../assets/moonBetting/tou6.png);
      }
    }
  }
}

//适配屏幕1920*360
.scaleSuo {
  transform: scale(0.3, 0.9) !important;
}

.scaleyangshi {
  width: calc(100% / 20);
}

.scaleactivityMiddle {
  width: 100%;
  height: 100%;
  flex: auto;
  overflow: hidden;
  position: relative;
  padding: 0 !important;
  margin: 0 -60px;
}

.scaleboxli {
  width: 80px !important;
}

.scaleicon {
  left: -9px !important;
}

.scaleusername {
  margin-left: 25px !important;
}

.scalelevel {
  margin-left: 9px !important;
}

.scaleSuo {
  transform: scale(0.3, 0.9);
}

//
.scaleSuo9 {
  transform: scale(0.3, 0.8);
}

.rank-info-process-v {
  margin-top: 6px;
}

.rank-info-process-v p:first-child {
  margin-bottom: 3px;
}

.mCenter {
  margin: 0 auto;
}

.pd20 {
  padding: 0 40px;
}
.dingwei {
  position: absolute;
}
.resopnse {
  position: absolute;
  top: 0;
}

.mt240 {
  margin-top: -240px;
}
.mr25 {
  margin: 0 -25px;
}
.ml25 {
  margin: 0 25px;
}
.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.message-list {
  display: inline-block;
}

.message {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  background-color: #f0f0f0;
}

.warp {
  width: 520px;
  height: 120px;
  margin: 0 auto;
  overflow: hidden;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.ul-item {
  display: flex;
}
.li-item {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  line-height: 120px;
  background-color: #999;
  color: #fff;
  text-align: center;
  font-size: 30px;
}
.index-index-swiper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.info-box-item {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #fff;
  padding:6px;
  position: relative;

}
.index-swiper-item {
  margin-bottom: 8px;
}


.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s; /* 延迟开始滚动 */
}

.marquee-text {
  display: inline-block;
  // padding-right: 7px;
  // margin-bottom: 7px;
  position: relative;
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.marqueeLeft {
  display: inline-block;
  animation-name: scroll_left;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s; /* 延迟开始滚动 */
}
@keyframes scroll_left {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.unnum__wrap {
  position: fixed;
  z-index: 999;
  right: -30px;
  margin-top: -39px;
  transform: rotate(10deg);
}
.unnum__wrap_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.unnum {
  width: 82px;
  height: 82px;
}

.unnum__wrap_mobile {
  position: fixed;
    z-index: 999;
    right: 44px;
    margin-top: -27px;
    transform: rotate(10deg);
}
.imageIcon{
  position: absolute;
    top: -10px;
    right: -12px;
    bottom: 0;
}
.item-head {
  // width: 82px;
  // height: 82px;
  position: relative;
  margin-right:6px;
}
.pd6{
  padding-left:3px;
}
.head-img {
  width: 51px;
    height: 51px;
    border-radius: 100%;

}
.imageIcons{
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -4px;
  right:-7px;
}

.activity-bg-img{
  width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 333333;
}
</style>
